import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown";

import Layout from '../components/layout';
import Contact from '../components/contact/contact';
import Banner from '../components/banner/banner';
import SubpageHeader from "../components/subpage-header/subpage-header";
import Container from "../components/container/container";

import '../styles/page.scss';

export const query = graphql`
  query PageQuery($id: String!) {
    strapiPages(strapiId: { eq: $id }) {
      pageTitle
      content
    }
  }
`

const PageTemplate = ({data}) => {
    return (
        <Layout>
            <section className="single-page">
                <SubpageHeader title={data.strapiPages.pageTitle} bg/>
                <Container>
                    <div className="content">
                        <ReactMarkdown source={data.strapiPages.content} />
                    </div>
                </Container>
            </section>
            <Banner >
                Masz pytania dotyczące oferty? Zadzwoń już teraz <strong>+48 509 94 88 11</strong>
            </Banner>
            <Contact />
        </Layout>
    )
}

export default PageTemplate;